<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.devices')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            {{ $t("message.type") }} :
            <br />
            <b-form-select v-model="searchData.modelType" :options="modelType" />
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t("message.name") }} :
            <br />
            <b-form-input v-model="searchData.name" />
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t("message.description") }} :
            <br />
            <b-form-input v-model="searchData.description" />
          </div>

          <div class="col-sm-6 col-md-3">
            <br />
            <b-btn variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>&nbsp;
            <b-btn variant="success" @click="showCreateModal()">
              {{ $t("message.create") }}&nbsp;
              <i class="fa fa-plus-square"></i>
            </b-btn>
          </div>
        </div>
      </b-form>
      <br />
      <div class="table-responsive">
        <table class="table2">
          <thead class="bg-primary" style="text-align: center">
            <tr>
              <th>{{ $t("message.type") }}</th>
              <th>{{ $t("message.modelType") }}</th>
              <th>{{ $t("message.name") }}</th>
              <th>{{ $t("message.description") }}</th>
              <th>{{ $t("message.createdAt") }}</th>
              <th>{{ $t("message.updatedAt") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody style="text-align: center">
            <template v-for="item in devices">
              <tr>
                <td>{{item.type}}</td>
                <td>{{item.modelType}}</td>
                <td>{{item.name}}</td>
                <td>{{item.description}}</td>
                <td>{{item.createdAt | moment("YYYY-MM-DD HH:mm")}}</td>
                <td>{{item.updatedAt | moment("YYYY-MM-DD HH:mm")}}</td>
                <td>
                  <b-btn variant="warning" @click="showEditModal(item)">แก้ไข</b-btn>
                </td>
              </tr>
            </template>
            <td></td>
          </tbody>
        </table>
      </div>
      <b-pagination
        size="md"
        :total-rows="totalRows"
        v-model="pagination"
        :per-page="itemPerPage"
        @input="pageChanged(pagination)"
      ></b-pagination>
    </b-card>

    <b-modal ref="createModal" @ok="createDevice()" :title="$t('message.create')">
      <div class="d-block">
        <table class="table2">
          <tr>
            <td>{{ $t('message.machineType') }}</td>
            <td>
              <b-form-select v-model="modalDataCreate.kiosktype" :options="kioskType" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.type') }}</td>
            <td>
              <b-form-select v-model="modalDataCreate.type" :options="type" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.modelType') }}</td>
            <td>
              <b-form-select v-model="modalDataCreate.modelType" :options="modelType" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.name') }}</td>
            <td>
              <input class="form-control" v-model="modalDataCreate.name" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.description') }}</td>
            <td>
              <input class="form-control" v-model="modalDataCreate.description" />
            </td>
          </tr>
        </table>
      </div>
    </b-modal>

    <b-modal ref="editModal" @ok="editDevice()" :title="$t('message.edit')">
      <div class="d-block">
        <table class="table2">
          <tr>
            <td>{{ $t('message.machineType') }}</td>
            <td>
              <b-form-select v-model="modalDataEdit.kiosktype" :options="kioskType" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.type') }}</td>
            <td>
              <b-form-select v-model="modalDataEdit.type" :options="type" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.modelType') }}</td>
            <td>
              <b-form-select v-model="modalDataEdit.modelType" :options="modelType" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.name') }}</td>
            <td>
              <input class="form-control" v-model="modalDataEdit.name" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('message.description') }}</td>
            <td>
              <input class="form-control" v-model="modalDataEdit.description" />
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import webServices from "../script";
export default {
  name: "devices",
  components: {},
  data() {
    return {
      totalRows: 1,
      pagination: 1,
      itemPerPage: 20,
      kioskType: [
        { value: "washing", text: "ฟาร์มเครื่องซักผ้า" },
        { value: "vending", text: "ตู้ขายน้ำอัติโนมัติ" },
        { value: "oilpump", text: "ตู้ขายน้ำมันออนไลน์" },
        { value: "dw_vending", text: "ตู้ขายน้ำดื่ม" }
      ],
      type: [
        { value: "washer", text: "เครื่องซักฝา" },
        { value: "dryer", text: "เครื่องอบผ้า" },
        { value: "vending", text: "เครื่องขายของ" },
        { value: "oilpump", text: "ตู้น้ำมัน" },
        { value: "board", text: "บอร์ดคอนโทรล" }
      ],
      modelType: [
        { value: "TOP_DOOR_WASH", text: "เครื่องซักฝาบน" },
        { value: "SIDE_DOOR_WASH", text: "เครื่องซักฝาหน้า" },
        { value: "DRYERS", text: "เครื่องอบผ้า" },
        { value: "SELL_SLOT", text: "เครื่องขายของ" },
        { value: "MANUAL_OUTPUT", text: "บอร์ดคอนโทรล" }
      ],
      searchData: {
        page: 1,
        rows: 20
      },
      devices: {},
      modalDataCreate: {},
      modalDataEdit: {}
    };
  },
  methods: {
    searchFn() {
      this.$Progress.start()
      webServices
        .getDevices(this.searchData)
        .then(res => {
          this.$Progress.finish()
          this.totalRows = res.data.count;
          this.devices = res.data.rows;
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log("error @getDevices");
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
        });
    },
    pageChanged(PageNum) {
      this.searchData.page = PageNum;
      this.searchFn();
    },
    showCreateModal () {
      this.$refs.createModal.show();
    },
    showEditModal (data) {
      this.modalDataEdit = data
      this.$refs.editModal.show();
    },
    createDevice () {
      this.$Progress.start()
      webServices.createDevice(this.modalDataCreate).then(res => {
        this.$Progress.finish()
        // console.log(res.data)
        if (res.data.success) {
          this.$toast.success({
            title: 'SUCCESS',
            message: 'Device has been created'
          })
          this.modalDataCreate = {}
          this.searchFn()
        } else {
          const errorText = {
            errorCode: res.data.errorCode,
            errorText: res.data.errorText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        }
      }).catch((error) => {
        this.$Progress.fail()
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
        console.log(error)
        this.$toast.warn({
          title: 'ERROR',
          message: 'Can not create new device'
        })
      })
    },
    editDevice () {
      this.$Progress.start()
      webServices.updateDevice(this.modalDataEdit).then(res => {
        this.$Progress.finish()
        // console.log(res.data)
        if (res.data.success) {
          this.$toast.success({
            title: 'SUCCESS',
            message: 'Device has been updated'
          })
          this.searchFn()
        } else {
          const errorText = {
            errorCode: res.data.errorCode,
            errorText: res.data.errorText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        }
      }).catch((error) => {
        this.$Progress.fail()
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
        console.log(error)
        this.$toast.warn({
          title: 'ERROR',
          message: 'Can not update device'
        })
      })
    }
  },
  mounted() {
    this.searchFn();
  }
};
</script>